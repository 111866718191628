<template>
  <div class="bossHomePage">
    <div class="box" v-if="isAdmin == 1">
      <div class="tab-box">
      <el-tabs type="card" v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane :label="item.Name" :name="item.Code" v-for="item in tabList" :key="item.Code"></el-tab-pane>
       
      </el-tabs>
      </div>
      <TotalStatistic ref="TotalStatistic" v-if="activeCmpt == '0'"/>
      <YSBStatistic ref="YSBStatistic" v-if="activeCmpt == 1"/>
      <GYBStatistic ref="GYBStatistic" v-if="activeCmpt == 2"/>
    </div>
     <!--非管理员显示统一页面-->
      <div class="nocontentBox" v-if="isAdmin == 0">
        <HomeNoContent />
      </div>
    </div>
</template>

<script>
import TotalStatistic from './homeCmpt/totalCmpt' // 首页总计统计
import YSBStatistic from './homeCmpt/YSBCmpt' // 运税PC宝统计
import GYBStatistic from './homeCmpt/GYBCmpt' // 工运宝统计
import HomeNoContent from '../../../components/businessCmpt/homeNoContent.vue';
import { _getIsAdmin } from '@/utils/storage'
import {getDataDict} from '@/api/common/common'
export default {
  data() {
    return {
      activeCmpt: '0', // tab栏切换    
      isAdmin: '', // 是否是管理员身份
      tabList: [], // tab栏
    }
  },

  methods: {
    //tabs页面跳转
    cmptChange(tab) {
      this.activeCmpt = tab.name
    },

    // 获取tab栏
    getTabList() {
      getDataDict({ type: 57 }).then((res) => {
        this.tabList = res.patterSetInfo;
        let item = {
          Code: '0',
          Name: '总计'
        }
        this.tabList.unshift(item)
      })
    }

  },
  components: {
    TotalStatistic,
    GYBStatistic,
    YSBStatistic,
    HomeNoContent
  },
  created() {
    this.isAdmin = _getIsAdmin()
    this.getTabList()
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.bossHomePage {
  .el-tabs__item {
    height: 2.25rem /* 36/16 */;
    line-height: 2.25rem;
    font-size: 1rem /* 16/16 */;
    font-weight: bold
  }
  .el-tabs__header {
    margin:0 0 .5rem /* 8/16 */
  }
}
</style>

<style scoped lang="scss">
@import '../../../assets/style/variable.scss';
.bossHomePage {
  .box {

    // background: #fff;
    
    .tab-box {
      background: #fff;
      padding: 1rem 0 0 1rem;
    }
  }
  .mr8 {
    margin-right: .5rem /* 8/16 */;
  }

}
</style>